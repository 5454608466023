.popular-shows-slider {
  .popular-shows-preview {
    .img-box {
      max-width: 367px;
      height: 270px;
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.1);
          transition: all 300ms ease-in;
        }
      }
    }
  }
  .slick-track{
    margin-left: 0!important;
    margin-left: 0!important;
  }
}

@media screen and (max-width: 767px) {
  .popular-shows-slider {
    .popular-shows-preview {
      .img-box {
        max-width: 100%;
      }
    }
  }
}
