.user-header-wrapper {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  .top-strip,
  .hometop-wrapper {
    padding-left: 110px;
    padding-right: 48px;
  }
  .right-part {
    .user-img {
      height: 47px;
      width: 47px;
      object-fit: cover;
      object-position: center;
    }
    .user-initials {
      height: 47px;
      width: 47px;
    }
  }
  .logo-img{
    max-width: 200px;
  }
}

.user-header-wrapper + div {
  padding-top: 110px;
}

.back-top-btn {
  position: fixed;
  bottom: 30px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 25px;
}

@media (max-width: 991px) {
  .user-header-wrapper {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    .top-strip,
    .hometop-wrapper {
      padding-left: 75px;
      padding-right: 20px;
    }
  }
}

@media (max-width: 767.5px) {
  .user-header-wrapper {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    .top-strip,
    .hometop-wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
    .right-part {
      .user-img {
        height: 35px;
        width: 35px;
        object-fit: cover;
        object-position: center;
      }
      .user-initials {
        height: 35px;
        width: 35px;
      }
    }
  }
  .back-top-btn {
    bottom: 50px;
  }
}

@media (max-width: 576px) {
  .user-header-wrapper + div {
    padding-top: 200px;
  }
}
