@import "../../../assets/scss/abstract/mixins";
.view-show-wrapper {
    .suggestion-list {
        @include flex;
        .suggestion-detail {
            @include flex-size(33.33%);
            padding: 10px;
            @include flex-direction(column);
            img {
                width: 100%;
                height: 200px;
            }
            .right {
                margin-top: 20px;
                h6 {
                    font-size: 20px;
                    margin-bottom: 10px;
                }
                .info {
                    font-size: 18px !important;
                    margin-bottom: 5px;
                    line-height: 24px;
                }
                .time {
                    font-size: 14px !important;
                    line-height: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .view-show-wrapper {
        .suggestion-list {
            .suggestion-detail {
                @include flex-size(50%);
                img {
                    height: 150px;
                }
                .right {
                    margin-top: 20px;
                    h6 {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                    .info {
                        font-size: 12px !important;
                        line-height: 18px;
                    }
                    .time {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .common-dashboard.view-show-wrapper .user-header-wrapper+div {
        padding-top: 190px;
    }
}