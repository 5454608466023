/*=============================
            Variables
  ===========================*/

/***** colors**********/

// purple shades====
$color-purple: #87449a;
$color-dark-purple: #883e7f;
// black shades ====
$color-dark: #2d2d2d;
$color-dark-2: #404040;
$color-dark-3: #343434;
$color-dark-4: #424141;
$color-dark-5: #898989;
$color-dark-6: #4b4b4b;
$color-dark-7: #424242;
$color-dark-9: #515151;
$color-black: #000000;
// greyd shades ====
$color-grey:#606060;
$color-grey-2:#B5B5B5;
$color-grey-3:#C4C4C4;
$color-grey-4:#F4F4F4;
$color-grey-5:#F2F2F2;
$color-grey-6:#E5E5E5;
$color-grey-8:  #F3F3F3;
$color-grey-7:#EFEFEF;
$color-grey-8: #7C7C7C;
$color-grey-9: #515151;
$color-grey-11:#E8E8E8;

$color-placeholder: #959595;
// white ====
$color-white: #ffffff;
// yellow shades====
$color-yellow: #ffa400;
$color-light-yellow: #ffbc42;

// red ====
$color-red: #c33f15;
$color-red-2: #ff3d00;
$color-red-3: #ff0000;
$color-red-4: #e00404;
$color-red-5: #ff7575;
$color-red-7: #8A0000;
$color-red-9: #FFC8C8;
// green ====
$color-green: #4caf50;
$color-green-2: #008000;
$color-green-3: #02af14;
$color-green-4: #1dac2c;
//blue =====
$color-sky-blue: #0382ff;
$color-blue: #24aae1;

/*=========fonts ======*/

$base-font-size: 15px;
$font-smallest: calc(1rem - 3px); // 12px
$font-small: calc(1rem - 2px); // 13px
$fs-5: calc(1rem + 3px); // 18px
$fs-7: calc(1rem + 9px); // 24px
$fs-8: calc(1rem + 10px); // 25px
$fs-9: calc(1rem + 21px); // 36px
$fs-10: calc(1rem + 19px); //34px
$fs-11: calc(1rem + 49px); //64px
$font-largest: calc(1rem + 62px); // 77px
$font-heading-4: calc(1rem + 5px); // 20px
$font-heading-6: calc(1rem - 1px); // 14px
$font-heading-5: calc(1rem + 1px); // 16px
$font-heading-3: calc(1rem + 11px); // 26px
$font-heading-2: calc(1rem + 15px); // 30px
$font-heading-1: calc(1rem + 23px); // 48px

/*=========fonts families======*/

$font-family-poppins: "Poppins", sans-serif;
$font-family-source-sans-pro: "Source Sans Pro", sans-serif;

$font-family-montserrat: "Montserrat", sans-serif;
/*=========Line Height======*/

$base-line-height: 24px;
$line-height-14: 14px;

/*=========letter spacing======*/

$letter-spacing-1: 1px;
$letter-spacing-2: 1px;
$letter-spacing-3: 1px;
// peach======
$color-peach: rgba(255, 164, 0, 0.33);
