@import "../../../../../../assets/scss/abstract/variable";
.comment-search-wrapper {
  .user-profile-img {
    height: 36px;
    width: 36px;
  }
  .user-initials{
    height: 36px;
    width: 36px;
    margin-right: 10px;
    font-size: 10px;
  }
  .comment-input {
    height: 50px;
    width: 482px;
  }
  .comment-box {
    border-radius: 10px;
  }
  .camera {
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    height: 50px;
  }
  .attachment {
    height: 50px;
  }
  .form-control {
    &::placeholder {
      font-size: 12px;
      font-weight: bold;
    }
    &:focus {
      color: $color-black;
      background-color: $color-grey-7;
      border-color: transparent;
      outline: 0;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .comment-search-wrapper {
    form{
      width: 100%;
    }
    .comment-input {
      width: 100%;
    }
  }
}
