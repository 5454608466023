.all-recipe-wrapper {
    .recipe-box {
        img {
            height: 187px;
        }
    }
}

@media (max-width:575.98px) {
    .all-recipe-wrapper {
        .recipe-box {
            img {
                height: 125px;
            }
        }
    }
}