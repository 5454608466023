.all-show-wrapper {
    .show-box {
        img {
            height: 270px;
        }
    }
}

@media(max-width:767px) {
    .all-show-wrapper {
        .show-box {
            img {
                height: 180px;
            }
        }
    }
}