@import "../../../../../../assets/scss/abstract/variable";
.comment-card-box {
  max-width: 530px;
  .user-image {
    width: 36px;
    height: 36px;
  }
  .menu {
    flex: 0 0 30px;
  }
  .user-comment {
    line-height: 13px;
  }
  .report {
    .report-action {
      top: 0px;
      right: 0;
      z-index: 1;
      background-color: $color-grey-3;
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    inset: 0px auto auto -65px !important;
    transform: none !important;
    z-index: 0;
    min-width: 100px;
    .dropdown-item:hover {
      background-color: transparent;
    }
  }
  .comment-img{
    max-width: 299px;
    max-height: 182px;
  }
}
