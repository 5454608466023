@media (max-width: 567px) {
  .grocery-creation {
    form {
      .add-item {
        > div {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 calc(50% - 5px);
          flex: 0 0 calc(50% - 5px);
          max-width: calc(50% - 5px);
        }
      }
    }
  }
}
