.reported-comment-card {
        
    .user-profile {
        margin-bottom: 35px;
        .user-profile-img {
            flex: 0 0 29px;
            max-width: 29px;
            margin-right: 7px;
            border-radius: 10px;
            .image {
                width: 100%;
                width: 29px;
                height: 29px;
                object-fit: cover;
                object-position: center;
            }
        }

        .user-profile-content{
            flex: 0 0 calc(100% - 40px);
        }
        
    }
}