.all-video-wrapper {
    .video-box {
        img {
            height: 187px;
        }
    }
}

@media (max-width:576px) {
    .all-video-wrapper {
        .video-box {
            img {
                height: 125px;
            }
        }
    }
}