.admin-top-tabs {
  padding: 15px 0;
  top: 0;
  width: 1470px;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 110px;
  z-index: 99;
  li {
    a {
      color: #000000;
      border: 1px solid transparent;
      border-radius: 4px;
      margin-right: 2px;
      padding: 0.5rem 1rem;
      &:hover,
      &.active {
        border: 1px solid #24aae1;
        
      }
    }
  }
  &+div{
    padding-top: 80px;
  }
}
