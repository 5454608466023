@import "../../../../assets//scss/abstract/variable";
.comments-recipe-tab.nav-tabs{
    border-bottom: transparent;
    .nav-link {
      color: $color-grey-2;
      font-size: $font-heading-6;
      font-weight: bold;
      padding: 5px 0;
      margin-right: 24px;
      position: relative;
      &.active, &:hover, &:focus {
        color: $color-black;
        border-color: $color-white;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          height: 2px;
          border-bottom: 2px solid $color-purple;
          border-bottom-width: medium;
        }
      }
    }
  }