@import "../../../../assets/scss/abstract/variable";

.faq-tabs {
  .nav-pills {
    .nav-link {
      background-color: $color-grey-4;
      color: $color-dark;
      font-size: $font-heading-5;
      font-weight: 600;
      &.active,
      &:hover {
        background-color: $color-grey-3;
        color: $color-dark;
      }
    }
  }
  .card-header {
    padding: 15px 0;
    background-color: transparent;
    border-bottom: 1px solid $color-dark-purple;
    margin-bottom: 0 !important;
    text-align: left;
  }
  .card-body {
    padding: 20px 0;
    border-bottom: 1px solid $color-dark-purple;
    .topic-desc {
      line-height: 20px !important;
    }
  }
}
