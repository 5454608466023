.grocery-creation{
    .grocery-creation-content{

        .btn-add-grocery-list{
            bottom: 30px;
            right: 48px;
        }
        .hometop-wrapper{
            display: none !important;
        }
        .user-header-wrapper + div{
            padding-top: 80px;
        }
    }
}

@media screen and (max-width: 767px) {
    .grocery-creation{
        .grocery-creation-content{
    
            .btn-add-grocery-list{
                bottom: 70px;
                right: 25px;
            }
           
        }
    }
    
}