html {
  scroll-behavior: smooth;
  font-size: $base-font-size;
}
body {
  font-family: $font-family-montserrat;
  color: $color-black;
  line-height: $base-line-height;
  background-color: $color-grey-6;
}
*,
*:focus {
  outline: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
ul {
  list-style: none;
  padding-left: 0;
}
a {
  text-decoration: none !important;
  color: $color-dark-purple;
}
a:hover {
  color: $color-dark-purple;
}
p {
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
/*****************************/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family-poppins;
  line-height: 1.2;
  font-weight: 600 !important;
}
h1,
.h1 {
  font-size: $font-heading-1; // 48px
}
h2,
.h2 {
  font-size: $font-heading-2; //30px
}
h3,
.h3 {
  font-size: $font-heading-3; // 26px
}
h4,
.h4 {
  font-size: $font-heading-4; // 20px
}
h5,
.h5 {
  font-size: $font-heading-5; //16 px
}
h6,
.h6 {
  font-size: $font-heading-6; // 14px
}
/******* Helpers ********************************************************************/
/*-----  fonts ---------- */
.font-poppins {
  font-family: $font-family-poppins !important;
}
.font-source-sans {
  font-family: $font-family-source-sans-pro !important;
}
.font-montserrat {
  font-family: $font-family-montserrat !important;
}
/* font sizes ======= */
.font-smallest {
  font-size: $font-smallest !important; //12px
}
.fs-small {
  font-size: $font-small !important; // 13px
}
.fs-14 {
  font-size: $font-heading-6 !important;
}
.fs-15 {
  font-size: $base-font-size !important;
}
.fs-16 {
  font-size: $font-heading-5 !important;
}
.fs-18 {
  font-size: $fs-5 !important;
}
.fs-20 {
  font-size: $font-heading-4 !important;
}
.fs-24 {
  font-size: $fs-7 !important;
}
.fs-25 {
  font-size: $fs-8 !important;
}
.fs-26 {
  font-size: $font-heading-3 !important;
}
.fs-30 {
  font-size: $font-heading-2 !important;
}
.fs-34 {
  font-size: $fs-10 !important;
}
.fs-36 {
  font-size: $fs-9 !important;
}
.fs-64 {
  font-size: $fs-11 !important;
}
.fs-48 {
  font-size: $font-heading-1 !important;
}
.font-largest {
  font-size: $font-largest !important;
}
/* text colors=== */
.text-black {
  color: $color-black !important;
}
.text-grey {
  color: #262626 !important;
}
.text-grey-2 {
  color: $color-dark-4;
}
.text-grey-3 {
  color: $color-dark-5;
}
.text-grey-4 {
  color: $color-grey-8;
}
.text-grey-5 {
  color: $color-grey-9;
}
.text-dark {
  color: $color-dark-3 !important;
}
.text-dark-2 {
  color: $color-dark-7 !important;
}

.text-primary,
a.text-primary:hover,
a.text-primary:focus {
  color: $color-dark-purple !important;
}
.text-danger,
a.text-danger:hover,
a.text-danger:focus {
  color: $color-red !important;
}
.text-danger-2,
a.text-danger-2:hover,
a.text-danger-2:focus {
  color: $color-red-2 !important;
}
.text-danger-3,
a.text-danger-3:hover,
a.text-danger-3:focus {
  color: $color-red-3 !important;
}
.text-danger-4,
a.text-danger-4:hover,
a.text-danger-4:focus {
  color: $color-red-4 !important;
}
.text-danger-5,
a.text-danger-5:hover,
a.text-danger-5:focus {
  color: $color-red-7 !important;
}
.text-success,
a.text-success:focus,
a.text-success:hover {
  color: $color-green !important;
}
.text-success-2,
a.text-success-2:focus,
a.text-success-2:hover {
  color: $color-green-2 !important;
}
.text-success-3,
a.text-success-3:focus,
a.text-success-3:hover {
  color: $color-green-3 !important;
}
.text-warning,
a.text-warning:focus,
a.text-warning:hover {
  color: $color-yellow !important;
}
.text-info,
a.text-info:hover,
a.text-info:focus {
  color: $color-sky-blue !important;
}
/* backgrounds ==== */
.bg-grey {
  background-color: $color-grey-4;
}
.bg-grey-2 {
  background-color: $color-grey-3;
}
.bg-grey-3 {
  background-color: $color-grey-5;
}
.bg-grey-4 {
  background-color: $color-grey-5 !important;
}
.bg-grey-5 {
  background-color: $color-grey-7;
}
.bg-grey-7 {
  background-color: $color-grey-11;
}
.bg-grey-9 {
  background-color: $color-grey-6;
}
.bg-primary {
  background-color: $color-purple !important;
}
.bg-warning {
  background-color: $color-light-yellow !important;
}
.bg-warning-1 {
  background-color: $color-yellow !important;
}
.bg-danger-1 {
  background-color: $color-red-5 !important;
}
.bg-info {
  background-color: $color-blue !important;
}
.bg-dark {
  color: $color-dark;
}
.bg-white {
  background-color: $color-white !important;
}
.bg-danger-2 {
  background-color: $color-red-9 !important;
}
// bg transparent
.bg-transparent-dark {
  background-color: rgba($color-black, 0.5) !important;
}
/*************Font Weight****************/
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-semi {
  font-weight: 600 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: 800 !important;
}
// =============================
.container {
  max-width: 1470px;
}
.text-underline {
  text-decoration: underline !important;
}
//==========
.line-height-14 {
  line-height: $line-height-14;
}
//=========borders
.border-6 {
  border-radius: 6px;
}

.border-radius-10 {
  border-radius: 10px;
}
// opacities ==============================
.opacity-one-fourth {
  opacity: 0.25;
}
.opacity-half {
  opacity: 0.5;
}
.opacity-three-fourth {
  opacity: 0.75;
}
.opacity-full {
  opacity: 1;
}
select.remove-default-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
// max width classes

.mw-25 {
  max-width: 25%;
}
.mw-50 {
  max-width: 50%;
}
.mw-75 {
  max-width: 75%;
}

//====== image-fit=====
.img-fit {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cursor-pointer {
  cursor: pointer;
}

// border-colors=-===={
.border-dark {
  border-color: #000000 !important;
}

.border {
  &.border-primary {
    border-color: $color-purple !important;
  }
  &.border-danger {
    border-color: $color-red !important;
  }
}

.text-deleted {
  text-decoration: line-through;
}

.slick-prev,
.slick-next,
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  z-index: 9;
  height: 50px;
  width: 50px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba($color-black, 0.5);
  opacity: 1;
}
.slick-prev {
  left: -00px;
}
.slick-next {
  right: -0px;
}
.slick-prev:before,
.slick-next:before {
  color: #87449a;
  font-size: 50px;
  position: absolute;
  top: 4px;
  left: 0;
}
// =======================media querries=============================

@media (max-width: 767px) {
  html {
    font-size: 14px;
  }
  h1,
  .h1 {
    font-size: $font-heading-2; // 48px
  }
  h2,
  .h2 {
    font-size: $font-heading-3; //30px
  }
  h3,
  .h3 {
    font-size: $font-heading-4; // 26px
  }
  h4,
  .h4 {
    font-size: $font-heading-5; // 20px
  }
  h5,
  .h5 {
    font-size: $font-heading-6; //16 px
  }
  h6,
  .h6 {
    font-size: $font-smallest; // 14px
  }

  .slick-prev,
  .slick-next,
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    height: 40px;
    width: 40px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
    top: 3px;
    left: 0.5px;
  }
}
