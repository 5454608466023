.saved-video {
    .saved-video-wrapper {
        .saved-video-box {
            .saved-vdo-img {
                max-width: 190px;
                max-height: 132px;
                height: 132px;
            }
        }
    }
    .hometop-wrapper {
        display: none !important;
    }
    .user-header-wrapper + div {
        padding-top: 105px !important;
    }
}

@media screen and (max-width: 576px) {
    .saved-video {
        .saved-video-wrapper {
            .saved-video-box {
                .saved-vdo-img {
                    max-width: 130px;
                    max-height: 91px;
                    height: 91px;
                }
            }
        }

        .user-header-wrapper + div {
            padding-top: 105px !important;
        }
    }
}

