.view-video-wapper {
  .hometop-wrapper {
    display: none !important;
  }

  .flowplayer{
    padding: 0;
    max-height: 530px;
  }

  .fa-video-player .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .view-video-wapper {
    .video-box {
      padding-top: 100px;
      iframe {
        height: 260px;
        width: 100%;
      }
    }
  }
}
