.product-wrapper {
  .hometop-wrapper {
    display: none !important;
  }
  .user-header-wrapper + div {
    padding-top: 120px;
  }
  .left-img{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .ViewCookbook-wrapper {
    .user-header-wrapper + div {
      padding-top: 80px;
    }
  }
}


