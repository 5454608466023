@import "../../../../assets/scss/abstract/variable";
.admin-top-search-wrapper {
    margin-bottom: 30px;
    max-width: 873px;
    .form-group {
        .form-control {
            padding: 8px 0;
            height: 45px !important;
        }
        .search-bar::placeholder{
            color: $color-black !important;
        }
    }
    .search-bar::placeholder {
      color: $color-black !important;
    }
  }
 