@import "../../../../../../assets/scss/abstract/variable";
.recipe-ingredients {
    .dropdown-toggle.btn {
        padding: 0;
        &::after {
            display: inline-block;
            content: " ";
            border-top: 0;
            border-right: transparent;
            border-bottom: 0;
            border-left: transparent;
            cursor: pointer;
            padding: 0;
            font-family: "FontAwesome";
            position: relative;
        }
    }
    .dropdown-menu {
        box-shadow: 2px 2px 2px 2px $color-grey-11;
        border: 0;
        z-index: 1;
        &.show {
            position: absolute;
            margin: 0px;
        }
    }
    .dropdown-item {
        &:hover {
            background-color: $color-white;
        }
    }
}

.recipe-heading {
    width: 110px;
}