@import "../../../assets/scss/abstract/variable";
@import "../../../assets/scss/abstract/mixins";
.forget-password-wrapper {
    .password-reset-info-popup {
        top: 0;
        left: 0;
        z-index: 999;
        display: none;
        .popup-wrappper {
            width: 100%;
            max-width: 290px;
        }
        &.active {
            @include flex;
        }
    }
}