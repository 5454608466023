.collections-category-wrapper {
  .top-search-wrapper {
    input {
      max-width: 873px;
      height: 45px !important;
    }
  }
  .notification-wrapper {
    display: none !important;
  }
}

@media (max-width: 567px) {
  .collections-category-wrapper {
    .collection-wrapper {
      padding-top: 150px;
      .comments-recipe-tab.nav-tabs {
        .nav-link {
          margin-right: 10px;
        }
      }
    }
  }
}
