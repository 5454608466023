.suggestions-wapper{
    .suggestion-detail{
        img{
            width: 130px;
            height: 91px;
        }
        .info{
            line-height:12px;
        }
        .time{
            line-height: 9px;
        }
        .premium-tag{
            bottom: 20px;
            left: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            img{
                max-width: 15px;
                height: auto !important;
            }
        }
    }
    
}