@import '../../../assets/scss/abstract/mixins';

.live-stream.user-live-feed {
  padding: 40px 0;
  .user-info {
      .user-img{
          @include flex-size(40px);
          img{
              height: 40px;
              width: 40px;
          }
      }
  }
}
