@import "../../../../assets/scss/abstract/variable";
.content-box {
  .header {
    .user-info {
      img {
        height: 30px;
        width: 30px;
      }
    }
    img {
      height: 20px;
      width: 20px;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    inset: 0px auto auto -65px!important;
    transform: none!important;
    z-index: 0;
    min-width: 100px;
    .dropdown-item:hover{
        background-color: transparent;
    }
  }
  .thumbnail {
    max-height: 345px;
    min-height: 345px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .content-box {
    .thumbnail {
      max-height: 370px;
      min-height: 370px;
    }
  }
}
