.post-wrapper {
  .hometop-wrapper {
    display: none !important;
  }
  .user-header-wrapper + div {
    padding-top: 110px;
  }
  .comment-search-wrapper {
    .comment-input {
      max-width: 435px;
    }
  }
  p {
    line-height: 18px;
  }

  .flowplayer{
    padding: 0;
    max-height: 550px;
  }

  .fa-video-player .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .post-wrapper {
    .user-header-wrapper + div {
      padding-top: 60px !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .post-wrapper {
    .comment-search-wrapper {
      .comment-input {
        width: 100% !important;
      }
    }
  }
}
