.new-episodes-slider {
  .new-episode-preview {
    .img-box {
      overflow: hidden;
      height: 316px;
      max-width: 429px;
      &:hover {
        img {
          transform: scale(1.1);
          transition: all 300ms ease-in;
        }
      }
    }
    .tag {
      left: 7.5px;
      bottom: 10px;
      z-index: 1;
      border-radius: 0px 3px 3px 0px;
    }
  }
   .slick-track{
    margin-left: 0!important;
    margin-left: 0!important;
  }
}

@media screen and (max-width: 767px){
  .new-episodes-slider {
    .new-episode-preview {
      .img-box {
        max-width: 100%;
      }
    }
  }
}