.live-stream{
    min-height: 100vh;
    padding: 75px 0;
    .live-stream-content {
        .viewers-comments {
            .viewers-comment-image {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 36px;
                flex: 0 0 36px;
                max-width: 36px;
                .user-image {
                    height: 36px;
                    width: 36px;
                }
            }
            .viewers-comment-content{
                flex: 0 0 calc(100% - 50px);
                padding-left: 5px;
            }
        }
    }
}

@media(max-width:767px){
    .live-stream {
        min-height: auto;
        padding: 50px 0;
    }
   
}