
/*=============================
            Mixins
  ===========================*/


/* border-radius mixin */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}


/* box-shadow mixin */

@mixin box-shadow($args...) {
    -webkit-box-shadow: $args;
    -moz-box-shadow: $args;
    -ms-box-shadow: $args;
    box-shadow: $args;
}


/* color with opacity mixin */

@mixin color-opacity($color,
$opacity) {
    color: rgba($color, $opacity);
}


/* border-color with opacity mixin */

@mixin border-color-opacity($color,
$opacity) {
    border-color: rgba($color, $opacity);
}

@mixin flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}


/* justify-content mixin */

@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}


/* align-items mixin */

@mixin align-items($align-horizontally) {
    -webkit-align-items: $align-horizontally;
    -moz-align-items: $align-horizontally;
    -ms-align-items: $align-horizontally;
    align-items: $align-horizontally;
}

// flex-direction
@mixin flex-direction($direction) {
    -webkit-box-orient: $direction;
    -webkit-box-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// flex-size
@mixin flex-size($width) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 $width;
    flex: 0 0 $width;
    max-width: $width;
}


/*text alignments*/

@mixin text-align($align) {
    text-align: $align;
}


/*text weight*/

@mixin font-weight($weight) {
    font-weight: $weight;
}