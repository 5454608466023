.popular-video-slider {
  .btn.btn-primary {
    background-color: transparent !important;
    border: none;
  }
  .popular-video-preview {
    .img-box {
      max-width: 257px;
      height: 187px;
      overflow: hidden;
      position: relative;
      &:hover {
        img {
          transform: scale(1.1);
          transition: all 300ms ease-in;
        }
      }
      .premium-tag{
        bottom: 10px;
        left: 0;
        border-radius: 0px 3px 3px 0px;
        img{
          max-width: 15px;
         
        }
      }
    }
    
  }
  .slick-track{
    margin-left: 0!important;
    margin-left: 0!important;
  }
}

@media screen and (max-width: 767px) {
  .popular-video-slider {
    .popular-video-preview {
      .img-box {
        max-width: 100%;
      }
    }
  }
}
