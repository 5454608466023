.shopping-home-wrapper {
  .notification-wrapper {
    display: none !important;
  }
  .top-search-wrapper {
    input {
      max-width: 843px;
      height: 45px !important;
    }
  }
  .user-header-wrapper + div {
    padding-top: 180px;
  }
}

@media (max-width: 576px) {
  .shopping-home-wrapper {
    .user-header-wrapper + div {
      padding-top: 160px;
    }
  }
}
