@import "../../../../assets/scss/abstract/mixins";
.watch-show-wrapper {
  .hometop-wrapper {
    display: none !important;
  }
  .video-box {
    padding-top: 120px;
    iframe {
      height: 530px;
      width: 100%;
    }
  }

  .flowplayer{
    padding: 0;
    max-height: 530px;
  }

  .fa-video-player .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 576px) {
  .watch-show-wrapper {
    .video-box {
      padding-top: 100px;
      iframe {
        height: 260px;
        width: 100%;
      }
    }
    .show-name{
      @include flex-size(100%);
    }
    .user-action-btn{
      @include flex-size(100%);
    }
  }
}