@import '../../../assets/scss/abstract/mixins';
.reported-content-wrapper {
    .reported-content-inner {
        >.container {
            padding-top: 150px;
            padding-bottom: 100px;
        }
    }
}

.reported-photo {
    .reported-image {
        .image {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

@media (max-width:576px) {
    .reported-content-wrapper {
        .action-wrapper {
            .action {
                @include flex-size(100%);
                margin-bottom:10px ;
            }
        }
        .reported-content-inner {
            >.container {
                padding-top: 150px;
                padding-bottom: 50px;
            }
        }
    }
}