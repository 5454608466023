.collections-category-wrapper {
  .top-search-wrapper {
    input {
      max-width: 873px;
      height: 45px !important;
    }
  }
  .notification-wrapper {
    display: none !important;
  }
  .cookbook-list {
    li {
      max-width: 148px;
      img {
        max-width: 135px;
        height: 187px;
      }
      p {
        line-height: 14px;
      }
    }
  }
  .hoodies-list {
    img {
      max-width: 247px;
      height: 238px;
    }
  }
  .tshirt-list {
    img {
      max-width: 247px;
      height: 238px;
    }
  }
  .aprons-list {
    img {
      max-width: 247px;
      height: 238px;
    }
  }

  .candle-list {
    img {
      max-width: 247px;
      height: 261px;
    }
  }
}
