.all-video-wrapper {
    .video-box {
        img {
            height: 187px;
        }
        .premium-tag{
            bottom: 20px;
            left: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            img{
                max-width: 15px;
                height: auto !important;
            }
        }
    }
}

@media (max-width:576px) {
    .all-video-wrapper {
        .video-box {
            img {
                height: 125px;
            }
        }
    }
}