
.reported-recipe {
    
    .reported-image {
      
        .image {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
