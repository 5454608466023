.upload-new-content {
    .upload-new-content-wrapper {
        .form-group {
            .form-control-file {
                display: none;
            }
            .form-file {
                width: 100%;
                display: block;
                
            }
        }
    }
}